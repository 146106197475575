import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { addDays, format, subDays } from 'date-fns';
import { calenderTheme } from 'themes/calenderTheme';

import {
  Autocomplete,
  Box,
  Container,
  Divider,
  IconButton,
  Stack,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Refresh } from '@mui/icons-material';

import { CustomDataGrid } from 'components/Common/CustomDataGrid';
import DateRangePicker from 'components/Common/DateRangePicker';

import { getAnalyticsEvents } from 'services/eventService';

import { ActiveMenuContext } from 'contexts/ActiveMenuContext';
import { useGroups } from 'contexts/GroupInfoContext';

import { ROUTE_KEY } from 'constants/RouteConstants';

import useDebounceHook from 'hooks/useDebounceHook';

import { AnalyticsResponse, GroupInfo } from 'types';
import { getESTFromUTC, getNumberofUniqueGroups, timeZoneAbbreviated } from 'utils/helpers';
import {
  formatStreamDuration,
  getStreamDurationInMinutes,
  getTotalStreamDuration,
} from 'utils/streamHelper';

import ManageDevices from './ManageDevices';

const Analytics = () => {
  const { setActiveMenu } = useContext(ActiveMenuContext);
  const [fromDate, setFromDate] = useState<Date | null>(subDays(new Date(), 8));
  const [toDate, setToDate] = useState<Date | null>(addDays(new Date(), 8));
  const { groups } = useGroups();
  const [reset, setReset] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<GroupInfo | null>(null);
  const [changedValue, setChangedValue] = useState<any>(null);
  const timeZone = timeZoneAbbreviated();
  const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const debouncedValue = useDebounceHook(changedValue, 2000);

  const columns: GridColDef<AnalyticsResponse>[] = [
    {
      field: 'name',
      width: 200,
      headerName: 'Name',
      sortable: true,
      renderCell: (params) => {
        const { row } = params;
        const { name } = row;
        return <Typography variant="body2">{name}</Typography>;
      },
    },
    {
      field: 'scheduledDate',
      width: 150,
      headerName: 'Scheduled Date',
      filterable: false,
      renderCell: (params) => {
        const { row } = params;
        const { scheduledTime } = row;
        return (
          <Typography variant="body2">{format(new Date(scheduledTime), 'MM/dd/yyyy')}</Typography>
        );
      },
      valueGetter: (params) => format(new Date(params.row.scheduledTime), 'MM/dd/yyyy'),
    },
    {
      field: 'scheduledTime',
      width: 110,
      filterable: false,
      headerName: 'Time',
      renderCell: (params) => {
        const { row } = params;
        const { scheduledTime } = row;
        const estTime = getESTFromUTC(scheduledTime);
        return (
          <Tooltip title={estTime} placement="top">
            <Typography variant="body2">
              {format(new Date(scheduledTime), 'hh:mm a')} {timeZone}
            </Typography>
          </Tooltip>
        );
      },
      valueGetter: (params) =>
        `${format(new Date(params.row.scheduledTime), 'hh:mm a')} ${timeZone}`,
    },
    {
      field: 'group',
      width: 120,
      headerName: 'Club',
      filterable: false,
      renderCell: (params) => {
        const { row } = params;
        const { group } = row;
        return <Typography variant="body2">{group}</Typography>;
      },
    },
    {
      field: 'tag',
      width: 250,
      headerName: 'Type',
      sortable: true,
      renderCell: (params) => {
        const { row } = params;
        const { tag } = row;
        if (!tag) {
          return (
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              -
            </Typography>
          );
        }
        return (
          <Tooltip title={tag} placement="top">
            <Typography variant="body2">{tag}</Typography>
          </Tooltip>
        );
      },
      valueGetter: (params) => (params.row.tag ? params.row.tag : '-'),
    },
    {
      field: 'streamStatus',
      width: 130,
      headerName: 'Status',
      renderCell: (params) => {
        const { row } = params;
        const { stream } = row;
        if (!stream?.status) {
          return (
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              -
            </Typography>
          );
        }
        return <Typography variant="body2">{stream?.status?.replace('_', ' ')}</Typography>;
      },
      valueGetter: (params) => params.row.stream?.status || '-',
    },
    {
      field: 'creatorName',
      width: 200,
      headerName: 'Creator Name',
      renderCell: (params) => {
        const { row } = params;
        const { firstName, lastName } = row.createdBy;
        return (
          <Typography variant="body2">
            {firstName} {lastName}
          </Typography>
        );
      },
      valueGetter: (params) => `${params.row.createdBy.firstName} ${params.row.createdBy.lastName}`,
    },
    {
      field: 'creatorEmail',
      width: 200,
      headerName: 'Creator Email',
      renderCell: (params) => {
        const { row } = params;
        const { email } = row.createdBy;
        return (
          <Tooltip title={email} placement="top">
            <Typography variant="body2">{email}</Typography>
          </Tooltip>
        );
      },
      valueGetter: (params) => params.row.createdBy?.email || '-',
    },
    {
      field: 'creatorGroup',
      width: 150,
      headerName: 'Creator Group',
      renderCell: (params) => {
        const { row } = params;
        const { group } = row.createdBy;
        return <Typography variant="body2">{group}</Typography>;
      },
      valueGetter: (params) => params.row.createdBy?.group || '-',
    },
    {
      field: 'destinations',
      width: 315,
      headerName: 'Destinations',
      sortable: true,
      renderCell: (params) => {
        const { row } = params;
        const { destinations } = row;
        if (!destinations.length) {
          return (
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              -
            </Typography>
          );
        }
        const destinationsCombined = destinations.join(', ');
        return (
          <Tooltip title={destinationsCombined} placement="top">
            <Typography variant="body2">{destinationsCombined}</Typography>
          </Tooltip>
        );
      },
      valueGetter: (params) =>
        params.row.destinations.length > 0 ? params.row.destinations.join(', ') : '-',
    },
    {
      field: 'streamDuration',
      width: 120,
      headerName: 'Duration',
      renderCell: (params) => {
        const { row } = params;
        const { stream } = row;
        const duration = stream.duration ? formatStreamDuration(stream.duration) : '';
        if (!duration) {
          return (
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              -
            </Typography>
          );
        }
        return <Typography variant="body2">{duration}</Typography>;
      },
      valueGetter: (params) =>
        params.row.stream.duration ? formatStreamDuration(params.row.stream.duration) : '-',
    },
    {
      field: 'streamDurationInMinutes',
      width: 120,
      headerName: 'Duration In Minutes',
      renderCell: (params) => {
        const { row } = params;
        const { stream } = row;
        const duration = stream.duration ? getStreamDurationInMinutes(stream.duration) : '';
        if (!duration) {
          return (
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              -
            </Typography>
          );
        }
        return <Typography variant="body2">{duration}</Typography>;
      },
      valueGetter: (params) =>
        params.row.stream.duration ? getStreamDurationInMinutes(params.row.stream.duration) : '-',
    },
    {
      field: 'streamStartedAt',
      width: 193,
      headerName: 'Started At',
      renderCell: (params) => {
        const { row } = params;
        const { stream } = row;
        if (!stream.streamStartedAt) {
          return (
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              -
            </Typography>
          );
        }
        const tooltipMessage = getESTFromUTC(stream.streamStartedAt);
        return (
          <Tooltip title={tooltipMessage} placement="top">
            <Typography variant="body2">
              {format(stream.streamStartedAt, 'MM/dd/yyyy hh:mm a')} {timeZone}
            </Typography>
          </Tooltip>
        );
      },
      valueGetter: (params) =>
        params.row.stream?.streamStartedAt
          ? `${format(params.row.stream?.streamStartedAt, 'MM/dd/yyyy hh:mm a')} ${timeZone}`
          : '-',
    },
    {
      field: 'streamEndedAt',
      width: 193,
      headerName: 'Ended At',
      renderCell: (params) => {
        const { row } = params;
        const { stream } = row;
        if (!stream.streamEndedAt) {
          return (
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              -
            </Typography>
          );
        }
        const tooltipMessage = getESTFromUTC(stream.streamStartedAt);
        return (
          <Tooltip title={tooltipMessage} placement="top">
            <Typography variant="body2">
              {format(stream.streamEndedAt, 'MM/dd/yyyy hh:mm a')} {timeZone}
            </Typography>
          </Tooltip>
        );
      },
      valueGetter: (params) =>
        params.row.stream?.streamEndedAt
          ? `${format(params.row.stream?.streamEndedAt, 'MM/dd/yyyy hh:mm a')} ${timeZone}`
          : '-',
    },
  ];

  useEffect(() => {
    setActiveMenu(ROUTE_KEY.ANALYTICS);
  });

  const getEvents = async (): Promise<AnalyticsResponse[]> => {
    const pathParams = {
      startDate: format(fromDate, 'yyyy-MM-dd'),
      endDate: format(toDate, 'yyyy-MM-dd'),
    };

    if (selectedGroup) {
      pathParams['group'] = selectedGroup.id;
    }

    const response = await getAnalyticsEvents(pathParams);
    response.sort((a, b) => -a.scheduledTime.localeCompare(b.scheduledTime));
    return response;
  };

  const {
    isFetching,
    isError,
    data: events = [],
    refetch,
  } = useQuery(['eventsInAnalytics'], () => getEvents(), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const resetFilters = () => {
    setReset(!reset);
    setSelectedGroup(null);
    setFromDate(subDays(new Date(), 8));
    setToDate(addDays(new Date(), 8));
    // setAnchorEl(null);
    // setCount(0);
  };

  useEffect(() => {
    refetch();
  }, [selectedGroup, reset]);

  useEffect(() => {
    if (anchorEl === null && count !== 0) {
      refetch();
    }
  }, [anchorEl]);

  useEffect(() => {
    if (debouncedValue) {
      refetch();
    }
  }, [debouncedValue]);

  useEffect(() => {
    // clean up session storage if any
    sessionStorage.removeItem('AUTH_ERROR');
    setActiveMenu(ROUTE_KEY.DASHBOARD);
  }, []);

  const [activeTab, setActiveTab] = useState('Analytics');

  const RenderTab = () => (
    <Stack direction="row" justifyContent={'space-between'} alignItems={'flex-start'}>
      <Stack direction="row" alignItems="center">
        <Typography
          variant="h5"
          sx={{
            cursor: 'pointer',
            fontWeight: 'bold',
            position: 'relative',
            textTransform: 'uppercase',
            borderBottom: `5px solid ${activeTab === 'Analytics' ? '#f86d20' : '#999999'}`,
          }}
          onClick={() => setActiveTab('Analytics')}
        >
          Analytics &nbsp;&nbsp;
        </Typography>
        <Typography
          variant="h5"
          sx={{
            cursor: 'pointer',
            fontWeight: 'bold',
            position: 'relative',
            textTransform: 'uppercase',
            borderBottom: `5px solid ${activeTab === 'Outdated' ? '#f86d20' : '#999999'}`,
          }}
          onClick={() => setActiveTab('Outdated')}
        >
          Outdated Devices &nbsp;
        </Typography>
      </Stack>
      {activeTab === 'Analytics' && (
        <Stack direction={{ md: 'row', sm: 'column' }} alignItems="center" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip title="Reset">
              <IconButton onClick={resetFilters}>
                <Refresh sx={{ color: 'primary.main' }} />
              </IconButton>
            </Tooltip>
            <Autocomplete
              id="select-team"
              size="small"
              value={selectedGroup}
              getOptionLabel={(option: GroupInfo) => `${option.name} (${option.id})`}
              options={groups || []}
              onChange={(_, value: GroupInfo) => setSelectedGroup(value)}
              renderInput={(params) => <TextField {...params} label="Filter by Group" />}
            />
          </Stack>
          &nbsp;&nbsp;
          <ThemeProvider theme={calenderTheme}>
            <DateRangePicker
              dateObj={{
                fromDate,
                toDate,
                count,
                anchorEl,
                setFromDate,
                setToDate,
                setCount,
                setAnchorEl,
                setChangedValue,
                reset,
              }}
            />
          </ThemeProvider>
        </Stack>
      )}
    </Stack>
  );
  const renderCurrentView = () =>
    activeTab === 'Analytics' ? (
      <>
        <Stack
          alignItems="flex-start"
          mt={4}
          direction={{ md: 'row', xs: 'column' }}
          spacing={{ md: 20, xs: 5 }}
          p={2}
          sx={{
            border: '1px solid',
            borderColor: 'grey.200',
            borderRadius: '7px',
            position: 'relative',
          }}
        >
          <Stack alignItems="flex-start">
            <Box bgcolor="common.white" sx={{ position: 'absolute', top: '-12px' }} px={1}>
              <Typography variant="body1" color="grey.200">
                {format(fromDate, 'MMM dd')} - {format(toDate, 'MMM dd')}
              </Typography>
            </Box>
            <Typography variant="h2" fontWeight="bold">
              {!isFetching ? events?.length ?? '-' : '-'}
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              Event(s)
            </Typography>
          </Stack>
          <Stack alignItems="flex-start">
            <Typography variant="h2" fontWeight="bold">
              {!isFetching ? getNumberofUniqueGroups(events) : '-'}
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              Group(s)
            </Typography>
          </Stack>
          <Stack alignItems="flex-start">
            <Typography variant="h2" fontWeight="bold">
              {!isFetching ? getTotalStreamDuration(events) : '-'}
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              Total Stream Duration
            </Typography>
          </Stack>
        </Stack>
        <Box height="55vh" mt={5}>
          <CustomDataGrid
            rows={events}
            rowIdKey="id"
            columns={columns}
            pageSize={20}
            showToolbar
            isLoading={isFetching}
          />
        </Box>
      </>
    ) : (
      <ManageDevices />
    );
  return (
    <Container maxWidth={false} sx={{ pt: '25px' }} disableGutters>
      {RenderTab()}
      {renderCurrentView()}
    </Container>
  );
};

export default Analytics;
