import { useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Box, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { ActiveViewHeader } from 'components/Common/ActiveViewHeader';
import { CustomDataGrid } from 'components/Common/CustomDataGrid';

import { ActiveMenuContext } from 'contexts/ActiveMenuContext';
import { useCurrentUserInfo } from 'contexts/CurrentUserInfoContext';
import { useToaster } from 'contexts/ToasterContext';

import { UserRole } from 'constants/AuthConstants';
import { ROUTE_KEY } from 'constants/RouteConstants';

import { OutdatedDevicesResponse } from 'types';
import { buildStyles } from 'utils/helpers';

import { getOutdatedMedialiveDevices } from '../services/ResourceService';

const styles = buildStyles({
  rawPopup: {
    padding: '10px',
    backgroundColor: '#F2F2F2',
    textAlign: 'left',
    fontSize: '12px',
    borderRadius: '4px',
  },
});

const ManageDevices = () => {
  const { setActiveMenu } = useContext(ActiveMenuContext);
  const { userInfo } = useCurrentUserInfo();
  const { triggerToast } = useToaster();

  const columns: GridColDef<OutdatedDevicesResponse>[] = [
    {
      field: 'name',
      width: 250,
      headerName: 'Name',
      sortable: true,
      filterable: true,
      renderCell: (params) => {
        const { row } = params;
        const { Name } = row.device;
        return (
          <Tooltip title={Name} placement="top">
            <Typography variant="body2">{Name || '-'}</Typography>
          </Tooltip>
        );
      },
      valueGetter: (params) => params.row.device?.Name || '-',
    },
    {
      field: 'id',
      width: 300,
      headerName: 'Id',
      filterable: true,
      sortable: false,
      renderCell: (params) => {
        const { row } = params;
        const { Id } = row.device;
        return <Typography variant="body2">{Id || '-'}</Typography>;
      },
      valueGetter: (params) => params.row.device?.Id || '-',
    },
    {
      field: 'type',
      width: 150,
      headerName: 'Type',
      renderCell: (params) => {
        const { row } = params;
        const { type } = row;
        return <Typography variant="body2">{type || '-'}</Typography>;
      },
      valueGetter: (params) => params.row.type || '-',
    },
    {
      field: 'group',
      width: 150,
      headerName: 'Club',
      filterable: true,
      sortable: true,
      renderCell: (params) => {
        const { row } = params;
        const { group } = row;
        return <Typography variant="body2">{group}</Typography>;
      },
      valueGetter: (params) => params.row.group || '-',
    },

    {
      field: 'region',
      width: 150,
      headerName: 'Region',
      renderCell: (params) => {
        const { row } = params;
        const { Arn } = row.device;
        return <Typography variant="body2">{Arn?.split(':')[3] || '-'}</Typography>;
      },
      valueGetter: (params) => params.row.device?.Arn?.split(':')[3] || '-',
    },
    {
      field: 'status',
      width: 150,
      headerName: 'Status',
      renderCell: (params) => {
        const { row } = params;
        const { ConnectionState } = row.device;
        return <Typography variant="body2">{ConnectionState || '-'}</Typography>;
      },
      valueGetter: (params) => params.row.device?.ConnectionState || '-',
    },
    // {
    //   field: 'details',
    //   width: 150,
    //   headerName: 'Details',
    //   sortable: false,
    //   renderCell: (params) => {
    //     const { row } = params;
    //     return <ViewRawDetail details={row.device} />;
    //   },
    // },
    // {
    //   field: 'arn',
    //   width: 400,
    //   filterable: true,
    //   sortable: false,
    //   headerName: 'Arn',
    //   renderCell: (params) => {
    //     const { row } = params;
    //     const { Arn } = row.device;
    //     return (
    //       <Tooltip title={Arn} placement="top">
    //         <Typography variant="body2">{Arn || '-'}</Typography>
    //       </Tooltip>
    //     );
    //   },
    //   valueGetter: (params) => params.row.device?.Arn || '-',
    // },
  ];

  useEffect(() => {
    setActiveMenu(ROUTE_KEY.DEVICES);
  });

  const getOutdatedDevices = async (): Promise<OutdatedDevicesResponse[]> => {
    return await getOutdatedMedialiveDevices();
  };

  const {
    isFetching,
    status,
    data: devices = [],
  } = useQuery(['outdatedDevices'], () => getOutdatedDevices(), {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  useEffect(() => {
    sessionStorage.removeItem('AUTH_ERROR');
    setActiveMenu(ROUTE_KEY.DASHBOARD);
  }, []);

  useEffect(() => {
    !isFetching &&
      status === 'error' &&
      triggerToast({
        message: 'Error while fetching the devices',
        type: 'error',
      });
  }, [status, isFetching]);

  return (
    <>
      {userInfo.role !== UserRole.APPLICATION_ADMIN && (
        <Box sx={{ pt: '25px' }}>
          <ActiveViewHeader
            title={'OUTDATED DEVICES'}
            customStyle={{
              before: { width: '80%' },
            }}
          />
        </Box>
      )}

      <Box height="70vh" mt={5}>
        <CustomDataGrid
          rows={devices}
          rowIdKey="Id"
          rowKeyFunc={(r) => `${r.device?.Id}-${r.group}`}
          columns={columns}
          pageSize={20}
          showToolbar
          isLoading={isFetching}
        />
      </Box>
    </>
  );
};

export default ManageDevices;

// un-comment this for adding view raw popup in a table
// export const ViewRawDetail = ({ details }) => {
//   const [popUpOpen, setPopUpOpen] = useState(false);
//   const [popupData, setPopupData] = useState({});

//   const payloadAttr: PopUpButtonProps[] = [
//     {
//       name: 'CANCEL',
//       handler: () => {
//         setPopUpOpen(false);
//       },
//       variant: 'outlined',
//     },
//   ];

//   const handlePopupOpen = (popupContent: any, type: 'string' | 'json') => {
//     if (type === 'json') {
//       setPopupData(JSON.stringify(popupContent, null, 2));
//     } else {
//       setPopupData(popupContent);
//     }
//     setPopUpOpen(true);
//   };

//   return (
//     <>
//       <Button
//         size="small"
//         sx={{ px: 0 }}
//         onClick={() => handlePopupOpen(details, typeof details === 'string' ? 'string' : 'json')}
//       >
//         View Raw &nbsp;
//         <LaunchRounded style={{ width: '14px', height: '14px' }} />
//       </Button>
//       <PopUp
//         isPopUpOpen={popUpOpen}
//         buttons={payloadAttr}
//         fullWidth
//         maxWidth="lg"
//         close={() => setPopUpOpen(false)}
//       >
//         <Typography
//           style={{ marginBottom: '30px', fontWeight: 'bold', textAlign: 'center' }}
//           variant="h6"
//         >
//           Raw Metadata Properties
//         </Typography>
//         {popupData ? (
//           Array.isArray(popupData) ? (
//             <pre style={styles.rawPopup as CSSProperties}>
//               {popupData?.map((d) => d?.log || '').join('\n') || ''}
//             </pre>
//           ) : (
//             <pre style={styles.rawPopup as CSSProperties}>{popupData as ReactNode}</pre>
//           )
//         ) : (
//           <Typography color="grey.600" style={{ marginTop: '30px' }}>
//             No Data
//           </Typography>
//         )}
//       </PopUp>
//     </>
//   );
// };
