import { Pagination, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

import { Center } from './Centered';

interface CustomDataGridProps<T> {
  columns: GridColDef[];
  rows: T[];
  rowIdKey: string;
  pageSize?: number;
  showToolbar?: boolean;
  isLoading?: boolean;
  showSearchbar?: boolean;
  rowKeyFunc?: (r) => string;
}

const CustomToolbar = (showToolbar: boolean, showSearchbar: boolean) => {
  return (
    <GridToolbarContainer>
      {showToolbar && (
        <>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </>
      )}
      {showSearchbar && (
        <GridToolbarQuickFilter
          sx={{
            position: 'absolute',
            top: '-60px',
            right: '150px',
            '.MuiSvgIcon-root': {
              color: 'primary.main',
              fontSize: '24px',
            },
            '.MuiInputBase-root': {
              '&:hover': {
                '&::before': {
                  borderColor: '#1083DA !important',
                  borderWidth: '2px',
                },
              },
              '&::before': {
                borderColor: 'primary.main',
                borderWidth: '2px',
              },
            },
          }}
        />
      )}
    </GridToolbarContainer>
  );
};

const CustomNoResultsOverlay = () => {
  return (
    <Center width="100%" height="100%" bgcolor="common.white">
      <Typography variant="h6">No Events Found!</Typography>
    </Center>
  );
};

export function CustomDataGrid<T>({
  rows,
  columns,
  rowIdKey,
  pageSize = 20,
  showToolbar = false,
  isLoading = false,
  showSearchbar = false,
  rowKeyFunc,
}: CustomDataGridProps<T>): JSX.Element {
  return (
    <DataGrid
      getRowId={(r) => (rowKeyFunc ? rowKeyFunc(r) : r[rowIdKey])}
      rows={rows}
      columns={columns}
      loading={isLoading}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pageSize,
          },
        },
      }}
      columnHeaderHeight={40}
      rowHeight={45}
      slots={{
        toolbar: () => CustomToolbar(showToolbar, showSearchbar),
        noResultsOverlay: CustomNoResultsOverlay,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      disableColumnMenu
      disableRowSelectionOnClick
      sx={{
        '.MuiDataGrid-columnHeaders': {
          backgroundColor: 'common.black',
          color: 'common.white',
          borderRadius: '0px',
        },
        '.MuiDataGrid-columnHeader': {
          padding: '0px 20px',
          borderBottom: 'none',
          '.MuiIconButton-root': {
            color: 'white',
          },
        },
        '.MuiDataGrid-cell': {
          padding: '0px 20px',
          borderBottom: 'none',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          fontSize: '14px',
        },
        '.MuiDataGrid-row': {
          border: 'none',
        },
        '.MuiDataGrid-row:nth-child(odd)': {
          backgroundColor: 'grey.100',
        },
        '.MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
      }}
    />
  );
}
